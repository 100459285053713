import * as React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layouts/layout'
import PostItem from '../components/posts/post-item'
import Metadata from '../components/common/metadata'

export default function Home({ data }) {
  const posts = data.allPost.edges

  return (
    <Layout>
      <Metadata
        title={`${data.site.siteMetadata.title} — Interesting clips from around the web`}
        description={data.site.siteMetadata.description}
        socialTitle="Clipped"
      />

      {posts.map(({ node }, index) => (
        <div>
          <PostItem
            key={index}
            title={node.title}
            created={node.created}
            content={node.content}
            slug={node.slug}
            link={node.link}
            showPermalink={true}
          />
        </div>
      ))}
    </Layout>
  );
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allPost(sort: {fields: created, order: DESC}) {
      edges {
        node {
          title
          type
          content
          link
          slug
          created
        }
      }
    }
  }
`
